import React, { useState } from "react";
import tw from "tailwind-styled-components";
import { Formik, Field, Form } from "formik";
import {
  InputGroup,
  FieldWrapper,
  Label,
  SubmitBtn,
  CombineInputGroup,
} from "../Styles/InputStyles";
import Loading from "../Loading";

import Select from "react-select";
import Model from "../Model";
import { useTranslation } from "react-i18next";

const AddTransportationModel = ({
  AddSubAgentLoading,
  postFarmerUser,

  setOpenUploadModel,

  data,
  // companyArr,
}) => {

  const { t, i18n } = useTranslation();
  const InitialValues = {

  };
  console.log(data)
  const SubmitHandler = (values) => {

    postFarmerUser(values);
  };

  const genderOption = [
    { value: "1", label: "Male" },
    { value: "2", label: "Female" },
  ];
  const buyerType = [
    { value: "1", label: "Consumer" },
    { value: "2", label: "Trade" },
  ];
  const cropList = data?.map((item) => ({
    value: item.cropType,
    label: item.cropName,
  }))

  return (
    <Model width={`w-11/12 max-w-lg `} setOpenModel={setOpenUploadModel}>
      <Title>Add Stock </Title>

      {AddSubAgentLoading && <Loading />}

      {!AddSubAgentLoading && (
        <Formik initialValues={InitialValues} onSubmit={SubmitHandler}>
          <Form>
            <Wrapper>
              <InputGroup>
                <Label htmlFor="cropType">CropType</Label>
                <FieldWrapper $select={true}>
                  <Field required name="cropType" id="cropType" autoComplete="off"  >
                    {(props) => (
                      <Select
                        className="w-full h-full"

                        required
                        options={cropList}
                        classNamePrefix="select"

                        onChange={(val) => {
                          props.form.setFieldValue("cropType", val.value)
                        }
                        }

                      />
                    )}
                  </Field>
                </FieldWrapper>
              </InputGroup>
              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="quantityProductRecived">Product Received Quantity</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="quantityProductRecived"
                      id="quantityProductRecived"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>

                <InputGroup>
                  <Label htmlFor="quantityProductAvailable">Product Available Quantity</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="quantityProductAvailable"
                      id="quantityProductAvailable"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>

              </CombineInputGroup>
              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="receivingDate">Receiving Date</Label>
                  <FieldWrapper>
                    <Field
                      type="date"
                      name="receivingDate"
                      id="receivingDate"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
                <InputGroup>
                  <Label htmlFor="quantitySold">Quantity Sold</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="quantitySold"
                      id="quantitySold"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
              </CombineInputGroup>
              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="buyerName">Buyer Name</Label>
                  <FieldWrapper>
                    <Field
                      type="text"
                      name="buyerName"
                      id="buyerName"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>

                <InputGroup>
                  <Label htmlFor="gender">Gender</Label>
                  <FieldWrapper $select={true}>
                    <Field required name="gender" id="gender" autoComplete="off"  >
                      {(props) => (
                        <Select
                          className="w-full h-full"

                          required
                          options={genderOption}
                          classNamePrefix="select"

                          onChange={(val) => {
                            props.form.setFieldValue("gender", val.value)
                          }
                          }
                        />
                      )}
                    </Field>
                  </FieldWrapper>
                </InputGroup>

              </CombineInputGroup>
              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="phone">Phone Number</Label>
                  <FieldWrapper>
                    <Field
                      type="number"

                      maxlength={9}
                      name="phone"
                      id="phone"
                      autoComplete="off"
                      className="truncate"

                    />
                  </FieldWrapper>
                </InputGroup>

                <InputGroup>
                  <Label htmlFor="buyerType">Type of buyer</Label>
                  <FieldWrapper $select={true}>
                    <Field required name="buyerType" id="buyerType" autoComplete="off"  >
                      {(props) => (
                        <Select
                          className="w-full h-full"

                          required
                          options={buyerType}
                          classNamePrefix="select"

                          onChange={(val) => {
                            props.form.setFieldValue("buyerType", val.value)
                          }
                          }
                        />
                      )}
                    </Field>
                  </FieldWrapper>
                </InputGroup>
              </CombineInputGroup>
              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="balance">Balance Quantity</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="balance"
                      id="balance"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
                <InputGroup>
                  <Label htmlFor="destination">Destination</Label>
                  <FieldWrapper>
                    <Field
                      type="text"
                      name="destination"
                      id="destination"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>


              </CombineInputGroup>


            </Wrapper>
            <BtnWrapper>
              <SubmitBtn type="submit">Add</SubmitBtn>
            </BtnWrapper>
          </Form>
        </Formik>
      )}
    </Model>
  );
};

const Wrapper = tw.div`
grid  gap-6 my-6 px-1`;


const Title = tw.h1`text-lg md:text-xl font-medium text-center text-gray-800`;
const BtnWrapper = tw.div`flex justify-center items-center mt-8`;

export default AddTransportationModel;
