import React from "react";
import tw from "tailwind-styled-components";
import { Formik, Field, Form } from "formik";
import Loading from "../Loading";
import {
  InputGroup,
  FieldWrapper,
  Label,
  SubmitBtn,
  CombineInputGroup,
} from "../Styles/InputStyles";

import Select from "react-select";
import Model from "../Model";

const EditFarmerModel = ({
  editAddUser,
  editEntryLoading,
  setEditUser,
  selectedData,
}) => {

  const gender = [
    { value: "1", label: "Male" },
    { value: "2", label: "Female" },
  ];
  const InitialValues = {
    name: selectedData?.name,
    age: selectedData?.age,
    phone: selectedData?.phone,
    village: selectedData?.village,
    district: selectedData?.district,
    id: selectedData?.id,
    gender: gender.find((option) => option.value === String(selectedData?.gender)),
  };
  console.log(InitialValues)



  const SubmitHandler = (values) => editAddUser(values);


  return (
    <Model width={`w-11/12 max-w-lg`} setOpenModel={setEditUser}>
      <Title>Edit Farmer </Title>

      {editEntryLoading && <Loading />}

      {!editEntryLoading && (
        <Formik initialValues={InitialValues} onSubmit={SubmitHandler}>
          <Form>
            <Wrapper>
              <InputGroup>
                <Label htmlFor="name">Name</Label>
                <FieldWrapper>
                  <Field
                    type="text"
                    name="name"
                    id="name"
                    autoComplete="off"
                    className="truncate"
                    required
                  />
                </FieldWrapper>
              </InputGroup>
              <InputGroup>
                <Label htmlFor="phone">Phone Number</Label>
                <FieldWrapper>
                  <Field
                    type="number"
                    name="phone"
                    id="phone"
                    autoComplete="off"
                    className="truncate"
                    required
                  />
                </FieldWrapper>
              </InputGroup>

              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="age">Age</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="age"
                      id="age"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>

                <InputGroup>
                  <Label htmlFor="gender">Gender</Label>
                  <FieldWrapper $select={true}>
                    <Field required name="gender" id="gender" autoComplete="off"  >
                      {(props) => (
                        <Select
                          className="w-full h-full"
                          {...props.field}

                          required
                          options={gender}

                          onChange={(selectedOption) => {
                            props.form.setFieldValue("gender", selectedOption);
                          }}

                        />
                      )}
                    </Field>
                  </FieldWrapper>
                </InputGroup>

              </CombineInputGroup>


              <Field type="hidden" name="id" value={selectedData?.id || ""} />
              {/* <CombineInputGroup> */}
              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="village">Village</Label>
                  <FieldWrapper>
                    <Field
                      type="text"
                      name="village"
                      id="village"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
                <InputGroup>
                  <Label htmlFor="district">District</Label>
                  <FieldWrapper>
                    <Field
                      type="text"
                      name="district"
                      id="district"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
              </CombineInputGroup>

            </Wrapper>
            <BtnWrapper>
              <SubmitBtn type="submit">Update</SubmitBtn>
            </BtnWrapper>
          </Form>
        </Formik>
      )}
    </Model>
  );
};

const Wrapper = tw.div`
grid gap-6 my-6 px-1 `;

const Title = tw.h1`text-lg md:text-xl font-medium text-center text-gray-800`;
const BtnWrapper = tw.div` w-full flex items-center justify-center space-x-10 mt-8`;

export default EditFarmerModel;
