import React, { useState, useEffect } from "react";
import tw from "tailwind-styled-components";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import Config from "../../Config";
import Images from "../../Images";
import Loading from "../../Components/Loading";
import Table from "../../Components/RawMaterial/Table";
import Navbar from "../../Components/Navbar";
import ChangePasswordModal from "../../Components/Admin/ChangePasswordModal";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";

import {
  BoxContainer,
  Underline,
  Bg,
} from "../../Components/Styles/PageStyles";
import {
  FieldWrapper,
  InputGroup,
  Label,
} from "../../Components/Styles/InputStyles";

import NotFoundModel from "../../Components/NotFoundModel";

import { useSelector } from "react-redux";

import { MdAdd } from "react-icons/md";

import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";

import EditFarmerModel from "../../Components/RawMaterial/EditRawMaterialModel";
import AddFarmerModel from "../../Components/RawMaterial/AddRawMaterialModel";
import ViewDetailModel from "../../Components/RawMaterial/ViewDetailModel";
const RawMaterial = () => {
  const user = useSelector((state) => state.UserReducer.user);
  const [changePasswordModel, setChangePasswordModel] = useState(false);

  const [openUploadModel, setOpenUploadModel] = useState(false);
  const [openRoleViewModel, setopenRoleViewModel] = useState(false);
  const [viewDetail, setViewDetail] = useState(false);
  const [selectedData, setSelectedData] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [filter, setFilter] = useState("name");
  const [editUser, setEditUser] = useState(false);

  const [startDate, setStartDate] = useState(
    moment(new Date()).subtract(7, "days").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const initialValues = {
    name: "",
    filterVal: "",
    startDate: moment(new Date()).subtract(7, "days").format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
  };

  const fetchFunction = async () => {
    const body = new FormData();
    body.append("startDate", startDate);
    body.append("endDate", endDate);

    return await axios.post(`${Config.apiUrl}/get-rawmaterial`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const getFieldAgentsSuccess = (res) => {
    if (res.data.status == "NOK" || res.data.data == false) error = true;
  };

  const getFieldAgentsError = (res) => {};

  const {
    isLoading: isLoading,
    mutate: getFieldAgentsMutate,
    error,
    data,
    isRefetching,
    refetch,
  } = useMutation(fetchFunction, {
    onSuccess: getFieldAgentsSuccess,
    onError: getFieldAgentsError,
  });

  useEffect(() => {
    getFieldAgentsMutate();
  }, []);

  const submitHandler = (values) => {
    const newValues = {
      startDate: moment(values.startDate).format("YYYY-MM-DD"),
      endDate: moment(values.endDate).format("YYYY-MM-DD"),
    };
    setStartDate(newValues.startDate);
    setEndDate(newValues.endDate);

    getFieldAgentsMutate();
  };

  //------- Add Single Entry -------
  const AddFarmerPostFunction = async (values) => {
    const body = new FormData();
    body.append("cropType", values.crop);
    body.append("gradea", values.gradea);
    body.append("gradeb", values.gradeb);
    body.append("balance", values.balance);
    body.append("balanceb", values.balanceb);
    body.append("source", values.source);
    body.append("date", values.date);
    body.append("issueDate", values.issueDate);
    body.append("issuegradea", values.issuegradea);
    body.append("issuegradeb", values.issuegradeb);
    return await axios.post(`${Config.apiUrl}/add-rawmaterial`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onAddUserSuccess = (data) => {
    console.log(data);
    if (data?.data?.status == "OK") {
      toast.success(data?.data?.msg || "Success");
      getFieldAgentsMutate();
    } else {
      toast.error(data?.data?.msg);
    }
    setSelectedData("");
    setOpenUploadModel(false);
  };

  const onAddUserError = (data) => {
    console.log(data?.response?.data?.msg);
    setOpenUploadModel(false);
    setSelectedData("");
    toast.error(data?.response?.data?.msg || "An Error Occurred");
  };

  const { isLoading: AddSubAgentLoading, mutate: postFarmerUser } = useMutation(
    AddFarmerPostFunction,
    {
      onSuccess: onAddUserSuccess,
      onError: onAddUserError,
    }
  );

  //------- Edit Single Entry -------
  const EditFieldAgentFunction = async (values) => {
    const body = new FormData();
    body.append("cropType", values.cropType);
    body.append("gradea", values.gradea);
    body.append("gradeb", values.gradeb);
    body.append("balance", values.balance);
    body.append("balanceb", values.balanceb);
    body.append("source", values.source);
    body.append("date", values.date);
    body.append("issueDate", values.issueDate);
    body.append("issuegradea", values.issuegradea);
    body.append("issuegradeb", values.issuegradeb);
    body.append("id", selectedData?.id);

    return await axios.post(`${Config.apiUrl}/update-rawmaterial`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token, //token is not in inspect
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onEditEntrySuccess = (data) => {
    if (data?.data?.status == "OK") {
      toast.success(data?.data?.msg || "Success");
      // refetch();
      getFieldAgentsMutate();
    } else {
      toast.error(data?.data?.msg || "An Error Occured");
    }
    setSelectedData("");
    setEditUser(false);
  };

  const onEditEntryError = (data) => {
    setEditUser(false);
    setSelectedData("");
    toast.error(data?.response?.data?.msg);
  };

  const { isLoading: editEntryLoading, mutate: editAddUser } = useMutation(
    EditFieldAgentFunction,
    {
      onSuccess: onEditEntrySuccess,
      onError: onEditEntryError,
    }
  );

  return (
    <>
      <Bg
        style={{
          backgroundImage: `url(${Images.BeamsBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "top left",
          height: "100vh",
        }}
      >
        <Navbar />

        {/* <Page> */}

        <BoxContainer>
          <div className="flex w-full justify-between items-center">
            <div>
              <Title>Raw Material</Title>
              <Underline />
            </div>
            <Uploader setOpenUploadModel={setOpenUploadModel} t={t} />
          </div>
          <div className="flex w-full justify-between items-center ">
            <Filters
              submitHandler={submitHandler}
              initialValues={initialValues}
              InitialLoading={isLoading}
              setFilter={setFilter}
              filter={filter}
              user={user}
              t={t}
            />
          </div>

          {openUploadModel && (
            <AddFarmerModel
              postFarmerUser={postFarmerUser}
              AddSubAgentLoading={AddSubAgentLoading}
              setOpenUploadModel={setOpenUploadModel}
              selectedData={selectedData}
              data={data?.data?.data?.crops}
            />
          )}

          {editUser && (
            <EditFarmerModel
              editAddUser={editAddUser}
              editEntryLoading={editEntryLoading}
              setEditUser={setEditUser}
              data={data?.data}
              selectedData={selectedData}
            />
          )}
          {viewDetail && (
            <ViewDetailModel
              setViewDetail={setViewDetail}
              selectedData={selectedData}
            />
          )}
          {!error && !isLoading && data && (
            <TableWrapper>
              <Table
                ApiData={error ? [] : data?.data?.data?.rawmaterialList}
                setopenRoleViewModel={setopenRoleViewModel}
                setSelectedData={setSelectedData}
                selectedData={selectedData}
                setEditUser={setEditUser}
                setChangePasswordModel={setChangePasswordModel}
                setViewDetail={setViewDetail}
              />
            </TableWrapper>
          )}

          {(isLoading || isRefetching) && <Loading />}
          {(error || data?.data?.data?.rawmaterialList.length == 0) &&
            !isLoading && <NotFoundModel />}
        </BoxContainer>
        {/* </Page> */}
      </Bg>
    </>
  );
};

const Filters = ({
  initialValues,
  submitHandler,
  InitialLoading,
  user,
  filter,
  setFilter,
  t,
}) => {
  return (
    <Formik initialValues={initialValues} onSubmit={submitHandler}>
      <Form>
        <FilterContainer>
          <InputGroup className="max-w-xs">
            <Label htmlFor="startDate">{t("Start_Date")}:</Label>
            <FieldWrapper className="h-10">
              <img
                src={Images.Calender}
                alt="Calender icon"
                className="w-3.5"
              />
              <Field type="date" name="startDate" id="startDate" class="ml-1" />
            </FieldWrapper>
          </InputGroup>

          <InputGroup className="max-w-xs">
            <Label htmlFor="endDate">{t("End_Date")}:</Label>
            <FieldWrapper className="h-10">
              <img
                src={Images.Calender}
                alt="Calender icon"
                className="w-3.5"
              />
              <Field type="date" name="endDate" id="endDate" class="ml-1" />
            </FieldWrapper>
          </InputGroup>

          <ApplyBtn disabled={InitialLoading} type="submit">
            {t("Date_filter")}
          </ApplyBtn>
        </FilterContainer>
      </Form>
    </Formik>
  );
};
const Uploader = ({ setOpenUploadModel, t }) => {
  return (
    <UploadWrapper>
      <Button type="button" onClick={() => setOpenUploadModel(true)}>
        <MdAdd className="text-white w-5 h-5" /> <p>Add Rawmaterial</p>
      </Button>
    </UploadWrapper>
  );
};

const TableWrapper = tw.h1`my-10 border bg-white  rounded-md overflow-auto`;

const Title = tw.h1`text-xl  text-gray-800 whitespace-nowrap   font-medium`;

const UploadWrapper = tw.div`
 w-full flex justify-end space-x-4 items-center `;

const Button = tw.button`text-white bg-custom-green hover:bg-green-700 w-40 flex items-center space-x-1 justify-center h-10   text-xs whitespace-nowrap rounded`;

const ApplyBtn = tw.button`${(p) =>
  p.$active
    ? "bg-gray-100"
    : ""} border whitespace-nowrap w-40  !mr-auto border-gray-200 flex space-x-1.5 justify-center items-center text-white  rounded-md px-6  cursor-pointer bg-custom-green hover:bg-green-700  h-10 text-sm 
     `;
const FilterContainer = tw.div`
grid grid-cols-2  w-full gap-4 md:flex   items-end mt-12 md:mt-8`;

export default RawMaterial;
