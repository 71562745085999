import React from "react";
import tw from "tailwind-styled-components";
import { Formik, Field, Form } from "formik";
import Loading from "../Loading";
import {
  InputGroup,
  FieldWrapper,
  Label,
  SubmitBtn,
  CombineInputGroup,
} from "../Styles/InputStyles";

import Model from "../Model";

const EditStockModel = ({
  editAddUser,
  editEntryLoading,
  setEditUser,
  selectedData,
}) => {

  const gender = [
    { value: "1", label: "Male" },
    { value: "2", label: "Female" },
  ];
  const InitialValues = {
    source: selectedData?.source,
    gradea: selectedData?.quantityRecivedGradeA,
    gradeb: selectedData?.quantityRecivedGradeB,
    quantityissuedGradeA: selectedData?.quantityissuedGradeA,
    quantityissuedGradeB: selectedData?.quantityissuedGradeB,
    destination: selectedData?.destination,
    balance: selectedData?.balance,
    balanceb: selectedData?.balanceb,
    id: selectedData?.id,
    cropType: selectedData?.cropType,
    cropName: selectedData?.cropName,
    recvdate: selectedData?.receivingDate,
    issueddate: selectedData?.issuedate,
  };




  const SubmitHandler = (values) => editAddUser(values);


  return (
    <Model width={`w-11/12 max-w-xl`} setOpenModel={setEditUser}>
      <Title>Edit Stock Management </Title>

      {editEntryLoading && <Loading />}

      {!editEntryLoading && (
        <Formik initialValues={InitialValues} onSubmit={SubmitHandler}>
          <Form>
            <Wrapper>
              <InputGroup>
                <Label htmlFor="cropType" >Crop Type</Label>
                <FieldWrapper>
                  <Field name="cropName" id="cropType" type="text" autoComplete="off" required disabled />
                </FieldWrapper>
              </InputGroup>
              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="gradea">Quantity Received Grade A(bags)</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="gradea"
                      id="gradea"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>

                <InputGroup>
                  <Label htmlFor="gradeb">Quantity Received Grade B(bags)</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="gradeb"
                      id="gradeb"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>

              </CombineInputGroup>
              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="recvdate">Receiving Date</Label>
                  <FieldWrapper>
                    <Field
                      type="date"
                      name="recvdate"
                      id="recvdate"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
                <InputGroup>
                  <Label htmlFor="source">Source</Label>
                  <FieldWrapper>
                    <Field name="source" id="source" type="text" autoComplete="off" required />
                  </FieldWrapper>
                </InputGroup>
              </CombineInputGroup>


              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="quantityissuedGradeA">Quantity Issued Grade A(bag)</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="quantityissuedGradeA"
                      id="quantityissuedGradeA"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>

                <InputGroup>
                  <Label htmlFor="quantityissuedGradeB">Quantity Issued Grade B(bag)</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="quantityissuedGradeB"
                      id="quantityissuedGradeB"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>

              </CombineInputGroup>
              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="balance">Balance Quantity Grade A</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="balance"
                      id="balance"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>

                <InputGroup>
                  <Label htmlFor="balanceb">Balance Quantity Grade B</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="balanceb"
                      id="balanceb"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>


              </CombineInputGroup>
              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="destination" >Destination</Label>
                  <FieldWrapper>
                    <Field name="destination" id="destination" type="text" autoComplete="off" required />
                  </FieldWrapper>
                </InputGroup>
                <InputGroup>
                  <Label htmlFor="issueddate">Issued Date</Label>
                  <FieldWrapper>
                    <Field
                      type="date"
                      name="issueddate"
                      id="issueddate"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
              </CombineInputGroup>


            </Wrapper>
            <BtnWrapper>
              <SubmitBtn type="submit">Update</SubmitBtn>
            </BtnWrapper>
          </Form>
        </Formik>
      )}
    </Model>
  );
};

const Wrapper = tw.div`
grid gap-6 my-6 px-1 `;

const Title = tw.h1`text-lg md:text-xl font-medium text-center text-gray-800`;
const BtnWrapper = tw.div` w-full flex items-center justify-center space-x-10 mt-8`;

export default EditStockModel;
