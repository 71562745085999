import React, { useEffect, useState } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { PrevBtn, NextBtn, PaginationWrapper } from "../Styles/PageStyles";
import Images from "../../Images";
import tw from "tailwind-styled-components";

import moment from "moment";
import { PaginationLimit } from "../../Config";
import { HiEye } from "react-icons/hi";

import { MdDelete, MdModeEdit, MdPassword } from "react-icons/md";


const Table = ({
  ApiData,
  setOpenDeleteModel,
  setSelectedData,
  setEditUser,
  setChangePasswordModel,
  setViewDetail,
}) => {
  const [data, setData] = useState([
    {
      deviceName: "",
      extractionTime: "",
      processingTime: "",
      drops: "",
      action: "",
    },
  ]);

  const edit = (item) => {
    setEditUser(true);
    setSelectedData(item);
  };


  const viewDetail = (item) => {
    setViewDetail(true);
    setSelectedData(item);
  }

  const ActionBtns = (item) => {
    return (
      <ActionBtnWrapper>
        <Btn
          title="View All Details"
          onClick={() => viewDetail(item)}
        >
          <HiEye className="text-gray-700" />
        </Btn>
        <Btn title="Edit Marketing" onClick={() => edit(item)}>
          <MdModeEdit className="text-gray-700" />
        </Btn>

      </ActionBtnWrapper>
    );
  };

  useEffect(() => {
    if (ApiData) {
      const tempData = ApiData.map((item) => ({
        type: item.type,
        capacity: item.capacity,
        gradea: item.totalQuantityloadedGradeA,
        gradeb: item.totalQuantityloadedGradeB,
        number: item.plateNumber,
        quantity: item.totalQuantityloaded,
        issueDate: moment(item.issueDate).format("DD-MM-YYYY"),
        cropType: item.cropName,

        action: ActionBtns(item),
      }));

      setData(tempData);
    }
  }, [ApiData]);



  const columns = React.useMemo(
    () => [
      {
        Header: "Crop Type",
        accessor: "cropType",
      },
      {
        Header: "Vehicle Type",
        accessor: "type",
      },
      {
        Header: "Vehicle Number",
        accessor: "number",
      },
      {
        Header: "Vehicle Capacity(ton)",
        accessor: "capacity",
      },
      {
        Header: "Total Quantity(bags)",
        accessor: "quantity",
      },
      {
        Header: "Quantity Grade A(bags)",
        accessor: "gradea",
      },
      {
        Header: "Quantity Grade A(bags)",
        accessor: "gradeb",
      },

      {
        Header: "Issued Date",
        accessor: "issueDate",
      },

      {
        Header: "Action",
        accessor: "action"
      }
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    pageOptions,
    page,
    state: { pageIndex, pageSize },
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      data,
      columns,
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    setPageSize(PaginationLimit);
  }, []);

  return (
    <>
      <CustomTable {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (
                      <img
                        src={Images.Arrow}
                        alt="down arrow"
                        className={`${column.isSortedDesc ? "-rotate-90" : "rotate-90"
                          } w-1.5 inline-block ml-1.5`}
                      />
                    ) : (
                      ""
                    )}
                  </span>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </CustomTable>

      <PaginationWrapper>
        <div className="px-2">
          Page{" "}
          <em>
            {pageIndex + 1} of {pageOptions.length}
          </em>
        </div>

        <div className="flex gap-1">
          <PrevBtn onClick={() => previousPage()} disabled={!canPreviousPage}>
            <img src={Images.Arrow} alt="arrow" className="w-2 opacity-75 " />
          </PrevBtn>
          <NextBtn onClick={() => nextPage()} disabled={!canNextPage}>
            <img
              src={Images.Arrow}
              alt="arrow"
              className="w-2 rotate-180 opacity-75"
            />
          </NextBtn>
        </div>
      </PaginationWrapper>
    </>
  );
};


const ActionBtnWrapper = tw.div`flex items-center space-x-3`;

const CustomTable = tw.table` w-full`;
const Thead = tw.thead`border-b`;
const Tbody = tw.tbody`border-b`;
const Tr = tw.tr`border-b rounded-md overflow-hidden `;
const Th = tw.th`text-left text-sm p-3 font-medium text-gray-500  `;
const Td = tw.td`p-3 text-sm`;

const Btn = tw.button`grid place-items-center border border-gray-300 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;


export default Table;
