import React, { useState } from "react";
import tw from "tailwind-styled-components";
import { Formik, Field, Form } from "formik";
import {
  InputGroup,
  FieldWrapper,
  Label,
  SubmitBtn,
  CombineInputGroup,
} from "../Styles/InputStyles";
import Loading from "../Loading";

import Select from "react-select";
import Model from "../Model";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const AddUserModel = ({
  AddUserLoading,
  postAddUser,
  setOpenUploadModel,

  data,
}) => {

  const { t, i18n } = useTranslation();
  const InitialValues = {

  };
  console.log(data)
  const SubmitHandler = (values) => {
    //console.log(values.roles);
    if (!values.roles) {
      toast.error("Please select roles for user");
      return;
    }
    postAddUser(values);
  };

  const Company = data?.map((item) => ({
    value: item.id,
    label: item.name,
  }))

  return (
    <Model width={`w-11/12 max-w-lg `} setOpenModel={setOpenUploadModel}>
      <Title>Add User</Title>

      {AddUserLoading && <Loading />}

      {!AddUserLoading && (
        <Formik initialValues={InitialValues} onSubmit={SubmitHandler}>
          <Form>
            <Wrapper>
              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="fName" >First Name</Label>
                  <FieldWrapper>
                    <Field name="fName" id="fName" type="text" autoComplete="off" required />
                  </FieldWrapper>
                </InputGroup>
                <InputGroup>
                  <Label htmlFor="lName">Last Name</Label>
                  <FieldWrapper>
                    <Field name="lName" id="lName" type="text" autoComplete="off" required />
                  </FieldWrapper>
                </InputGroup>
              </CombineInputGroup>
              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="email">Email</Label>
                  <FieldWrapper>
                    <Field
                      type="email"
                      name="email"
                      id="email"
                      autoComplete="off"
                      className="truncate"
                    // required
                    />
                  </FieldWrapper>
                </InputGroup>

                <InputGroup>
                  <Label htmlFor="phone">Phone Number</Label>
                  <FieldWrapper>
                    <Field
                      type="number"

                      maxlength={9}
                      name="phone"
                      id="phone"
                      autoComplete="off"
                      className="truncate"
                      required

                    />
                  </FieldWrapper>
                </InputGroup>
              </CombineInputGroup>
              <InputGroup>
                <Label htmlFor="password">Password</Label>
                <FieldWrapper>
                  <Field
                    type="password"
                    name="password"
                    id="password"
                    autoComplete="off"
                    className="truncate"
                    required
                  />
                </FieldWrapper>
              </InputGroup>
              <InputGroup>
                <Label htmlFor="type">Company Name</Label>
                <FieldWrapper $select={true}>
                  <Field required name="type" id="type" autoComplete="off"  >
                    {(props) => (
                      <Select
                        className="w-full h-full"

                        required
                        options={Company}
                        classNamePrefix="select"

                        onChange={(val) => {
                          props.form.setFieldValue("roles", val.value)
                        }
                        }
                      />
                    )}
                  </Field>
                </FieldWrapper>
              </InputGroup>


            </Wrapper>
            <BtnWrapper>
              <SubmitBtn type="submit">Add</SubmitBtn>
            </BtnWrapper>
          </Form>
        </Formik>
      )}
    </Model>
  );
};

const Wrapper = tw.div`
grid  gap-6 my-6 px-1`;
const SampleFile = tw.a` w-full pt-3 text-blue-500 underline text-sm text-center`;

const Title = tw.h1`text-lg md:text-xl font-medium text-center text-gray-800`;
const BtnWrapper = tw.div`flex justify-center items-center mt-8`;

export default AddUserModel;
