import React, { useState, useEffect } from "react";
import tw from "tailwind-styled-components";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import Config from "../../Config";
import Images from "../../Images";
import Loading from "../../Components/Loading";
import Table from "../../Components/Company/Table";
import Navbar from "../../Components/Navbar";

import { ToastContainer, toast } from "react-toastify";
import moment from "moment";

import {
  Bg,
  BoxContainer,
  Underline,
} from "../../Components/Styles/PageStyles";

import NotFoundModel from "../../Components/NotFoundModel";

import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import ViewDetailModel from "../../Components/Company/ViewDetailModel";
const Company = () => {
  const user = useSelector((state) => state.UserReducer.user);
  const [changePasswordModel, setChangePasswordModel] = useState(false);

  const [openRoleViewModel, setopenRoleViewModel] = useState(false);
  const [viewDetail, setViewDetail] = useState(false);
  const [selectedData, setSelectedData] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [filter, setFilter] = useState("name");
  const [editUser, setEditUser] = useState(false);

  const [startDate, setStartDate] = useState(
    moment(new Date()).subtract(7, "days").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const initialValues = {
    name: "",
    filterVal: "",
    startDate: moment(new Date()).subtract(7, "days").format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
  };

  const fetchFunction = async () => {
    const body = new FormData();
    body.append("startDate", startDate);
    body.append("endDate", endDate);
    body.append("filterType", filter);
    body.append("filterValue", filterValue);
    return await axios.post(`${Config.apiUrl}/get-company`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const getFieldAgentsSuccess = (res) => {
    if (res.data.status == "NOK" || res.data.data == false) error = true;
  };

  const getFieldAgentsError = (res) => {};

  const {
    isLoading: isLoading,
    mutate: getFieldAgentsMutate,
    error,
    data,
    isRefetching,
    refetch,
  } = useMutation(fetchFunction, {
    onSuccess: getFieldAgentsSuccess,
    onError: getFieldAgentsError,
  });

  useEffect(() => {
    getFieldAgentsMutate();
  }, []);

  return (
    <>
      <Bg
        style={{
          backgroundImage: `url(${Images.BeamsBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "top left",
          height: "100vh",
        }}
      >
        <Navbar />

        <BoxContainer>
          <div className="flex w-full justify-between items-center">
            <div>
              <Title>Companies</Title>
              <Underline />
            </div>
          </div>

          {viewDetail && (
            <ViewDetailModel
              setViewDetail={setViewDetail}
              selectedData={selectedData}
            />
          )}
          {!error && !isLoading && data && (
            <TableWrapper>
              <Table
                ApiData={error ? [] : data?.data?.data?.companyList}
                setopenRoleViewModel={setopenRoleViewModel}
                setSelectedData={setSelectedData}
                selectedData={selectedData}
                setViewDetail={setViewDetail}
              />
            </TableWrapper>
          )}

          {(isLoading || isRefetching) && <Loading />}
          {error && !isLoading && <NotFoundModel />}
        </BoxContainer>
      </Bg>
    </>
  );
};

const TableWrapper = tw.h1`my-10 border bg-white  rounded-md overflow-auto`;

const Title = tw.h1`text-xl  text-gray-800 whitespace-nowrap   font-medium`;

export default Company;
