import React, { useState } from "react";
import tw from "tailwind-styled-components";
import { Formik, Field, Form } from "formik";
import {
  InputGroup,
  FieldWrapper,
  Label,
  SubmitBtn,
  CombineInputGroup,
} from "../Styles/InputStyles";
import Loading from "../Loading";

import Select from "react-select";
import Model from "../Model";
import { useTranslation } from "react-i18next";


const AddWareHouseModel = ({
  AddSubAgentLoading,

  postSingleEntry,
  setOpenUploadModel,

  data,
  // companyArr,
}) => {

  const { t, i18n } = useTranslation();
  const InitialValues = {

  };
  console.log(data)
  const SubmitHandler = (values) => {

    postSingleEntry(values);
  };

  const Roles = [
    // { value: "1", label: "Admin" },
    { value: "2", label: "Aggregators" },
    { value: "3", label: "Warehouses" },
    { value: "4", label: "Farmers" }


  ];
  const Company = data?.map((item) => ({
    value: item.id,
    label: item.name,
  }))

  return (
    <Model width={`w-11/12 max-w-lg `} setOpenModel={setOpenUploadModel}>
      <Title>Add Warehouse Manager </Title>

      {AddSubAgentLoading && <Loading />}

      {!AddSubAgentLoading && (
        <Formik initialValues={InitialValues} onSubmit={SubmitHandler}>
          <Form>
            <Wrapper>
              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="fName" >First Name</Label>
                  <FieldWrapper>
                    <Field name="fName" id="fName" autoComplete="off" type="text" required />
                  </FieldWrapper>
                </InputGroup>
                <InputGroup>
                  <Label htmlFor="lName">Last Name</Label>
                  <FieldWrapper>
                    <Field name="lName" id="lName" autoComplete="off" type="text" required />
                  </FieldWrapper>
                </InputGroup>
              </CombineInputGroup>
              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="email">Email</Label>
                  <FieldWrapper>
                    <Field
                      type="email"
                      name="email"
                      id="email"
                      autoComplete="off"
                      className="truncate"
                    // required
                    />
                  </FieldWrapper>
                </InputGroup>

                <InputGroup>
                  <Label htmlFor="phone">Phone Number</Label>
                  <FieldWrapper>
                    <Field
                      type="number"

                      maxlength={9}
                      name="phone"
                      id="phone"
                      autoComplete="off"
                      className="truncate"
                      required

                    />
                  </FieldWrapper>
                </InputGroup>
              </CombineInputGroup>


              <InputGroup>
                <Label htmlFor="password">Password</Label>
                <FieldWrapper>
                  <Field
                    type="password"
                    name="password"
                    id="password"
                    autoComplete="off"
                    className="truncate"
                    required
                  />
                </FieldWrapper>
              </InputGroup>
              <InputGroup>
                <Label htmlFor="roles">Roles</Label>
                <FieldWrapper $select={true}>
                  <Field required name="roles" id="roles" autoComplete="off">
                    {(props) => (
                      <Select
                        className="w-full h-full pl-2"
                        options={Roles}
                      />
                    )}
                  </Field>
                </FieldWrapper>
              </InputGroup>

            </Wrapper>
            <BtnWrapper>
              <SubmitBtn type="submit">Add</SubmitBtn>
            </BtnWrapper>
          </Form>
        </Formik>
      )}
    </Model>
  );
};

const Wrapper = tw.div`
grid  gap-6 my-6 px-1`;


const Title = tw.h1`text-lg md:text-xl font-medium text-center text-gray-800`;
const BtnWrapper = tw.div`flex justify-center items-center mt-8`;

export default AddWareHouseModel;
