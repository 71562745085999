import { Route, Routes } from "react-router-dom";
import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux"; 
import moment from "moment";

import Login from "./Pages/LoginCopyPage";
import DashBoardNew from "./Pages/Admin/DashBoardNew";
import Users from "./Pages/Admin/Users";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Profile from "./Pages/Profile";
import AddProduction from "./Pages/Admin/AddProducion";
import RegisterPage from "./Pages/RegisterPage";
import ForgotPasswordPage from "./Pages/ForgotPassword";
import OtpPage from "./Pages/Otp";
import "./i18";
import { Remove_User } from "./Redux/actions";
import FieldAgents from "./Pages/Admin/FieldAgents";
import WarehouseManager from "./Pages/Admin/WarehouseManager";
import Farmers from "./Pages/Admin/Farmer";
import Production from "./Pages/Admin/Production";
import Harvesting from "./Pages/Admin/Harvesting";
import EditProduction from "./Pages/Admin/EditProduction";
import RawMaterial from "./Pages/WarehouseManager/RawMaterial";
import ProcessedMaterial from "./Pages/WarehouseManager/ProcessedMaterial";
import Company from "./Pages/Admin/Company";
import Marketing from "./Pages/FieldAgent/Marketing";
import StockManagement from "./Pages/FieldAgent/StockManagement";
import Transportation from "./Pages/FieldAgent/Transportation";
import Distribution from "./Pages/WarehouseManager/Distribution";
import StockManagementSelling from "./Pages/WarehouseManager/StockManagementSelling";

function App() {
  const user = useSelector((state) => state.UserReducer.user);

  const dispatch = useDispatch();

  const isSessionExpired = () => {
    if (user) {
      const expireAt = user?.expireAt;
      const currentTime = moment().valueOf();
      const isExpired = moment(currentTime).isAfter(expireAt);
      if (isExpired) {
        window.location.href = "/";
        dispatch(Remove_User());
      }
    }
  };

  useEffect(() => isSessionExpired(), []);
 
  if (!user)
    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={10000}
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover={false}
        />
        <Routes>
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/get-otp" element={<OtpPage />} />
          <Route path="*" index element={<Login />} />
        </Routes>
      </>
    );

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={10000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
      />

      <Routes>
        <Route path="/login" element={<Login />} />
        {user?.userType == 1 && (
          <>
            <Route path="/" element={<DashBoardNew />} />
            <Route path="/dashboard" element={<DashBoardNew />} />
            <Route path="/users" element={<Users />} />
            <Route path="/companies" element={<Company />} />
            <Route path="/field-agents" element={<FieldAgents />} />
            <Route path="/warehouse-manager" element={<WarehouseManager />} />

            <Route path="/profile" element={<Profile />} />
          </>
        )}

        {/* ADMIN */}
        {user?.userType == 2 && (
          <>
            <Route path="/" element={<DashBoardNew />} />
            <Route path="/dashboard" element={<DashBoardNew />} />
            <Route path="/users" element={<Users />} />
            <Route path="/field-agents" element={<FieldAgents />} />
            <Route path="/warehouse-manager" element={<WarehouseManager />} />

            <Route path="/profile" element={<Profile />} />
          </>
        )}

        {/* Filed Agent */}
        {user?.userType == 3 && (
          <>
            <Route path="/" element={<DashBoardNew />} />
            <Route path="/dashboard" element={<DashBoardNew />} />
            <Route path="/users" element={<Users />} />
            <Route path="/farmers" element={<Farmers />} />
            <Route path="/production-records" element={<Production />} />
            <Route path="/add-production" element={<AddProduction />} />
            <Route path="/edit-production/:id" element={<EditProduction />} />
            <Route path="/harvesting" element={<Harvesting />} />
            <Route path="/marketing" element={<Marketing />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/stock-management" element={<StockManagement />} />
            <Route path="/transportation" element={<Transportation />} />
          </>
        )}

        {/* Warehouse Manager */}
        {user?.userType == 4 && (
          <>
            <Route path="/" element={<DashBoardNew />} />
            <Route path="/dashboard" element={<DashBoardNew />} />
            <Route path="/users" element={<Users />} />
            <Route path="/field-agents" element={<FieldAgents />} />
            <Route path="/warehouse-manager" element={<WarehouseManager />} />
            <Route path="/raw-material" element={<RawMaterial />} />
            <Route path="/processes-product" element={<ProcessedMaterial />} />
            <Route path="/distribution" element={<Distribution />} />
            <Route
              path="/stock-management-selling"
              element={<StockManagementSelling />}
            />
            <Route path="/profile" element={<Profile />} />
          </>
        )}
      </Routes>

      {/* <Footer /> */}
    </>
  );
}

export default App;
