import React from "react";
import tw from "tailwind-styled-components";
import { Formik, Field, Form } from "formik";
import Loading from "../Loading";
import {
  InputGroup,
  FieldWrapper,
  Label,
  SubmitBtn,
  CombineInputGroup,
} from "../Styles/InputStyles";

import Model from "../Model";

const EditTransportationModel = ({
  editAddUser,
  editEntryLoading,
  setEditUser,
  selectedData,
}) => {


  const InitialValues = {
    number: selectedData?.plateNUmber,
    gradea: selectedData?.totalQuantityloadedGradeA,
    gradeb: selectedData?.totalQuantityloadedGradeA,
    type: selectedData?.type,
    capacity: selectedData?.capacity,
    id: selectedData?.id,
    issueddate: selectedData?.issuedate,
    cropType: selectedData?.cropType,
    cropName: selectedData?.cropName,
  };




  const SubmitHandler = (values) => editAddUser(values);


  return (
    <Model width={`w-11/12 max-w-lg`} setOpenModel={setEditUser}>
      <Title>Edit Stock Management </Title>

      {editEntryLoading && <Loading />}

      {!editEntryLoading && (
        <Formik initialValues={InitialValues} onSubmit={SubmitHandler}>
          <Form>
            <Wrapper>
              <InputGroup>
                <Label htmlFor="cropType" >Crop Name</Label>
                <FieldWrapper>
                  <Field name="cropName" id="cropType" type="text" autoComplete="off" required disabled />
                </FieldWrapper>
              </InputGroup>
              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="number">Vehicle Number</Label>
                  <FieldWrapper>
                    <Field
                      type="text"
                      name="number"
                      id="number"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>

                <InputGroup>
                  <Label htmlFor="type">Vehicle Type</Label>
                  <FieldWrapper>
                    <Field
                      type="text"
                      name="type"
                      id="type"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>

              </CombineInputGroup>
              <InputGroup>
                <Label htmlFor="capacity">Vehicle Capacity(ton)</Label>
                <FieldWrapper>
                  <Field
                    type="number"
                    name="capacity"
                    id="capacity"
                    autoComplete="off"
                    className="truncate"
                    required
                  />
                </FieldWrapper>
              </InputGroup>
              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="gradea">Quantity Grade A(bags)</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="gradea"
                      id="gradea"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>

                <InputGroup>
                  <Label htmlFor="gradeb">Quantity Grade B(bags)</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="gradeb"
                      id="gradeb"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>

              </CombineInputGroup>

              <InputGroup>
                <Label htmlFor="issuedate">Issued Date</Label>
                <FieldWrapper>
                  <Field
                    type="date"
                    name="issuedate"
                    id="issuedate"
                    autoComplete="off"
                    className="truncate"
                    required
                  />
                </FieldWrapper>
              </InputGroup>



            </Wrapper>
            <BtnWrapper>
              <SubmitBtn type="submit">Update</SubmitBtn>
            </BtnWrapper>
          </Form>
        </Formik>
      )}
    </Model>
  );
};

const Wrapper = tw.div`
grid gap-6 my-6 px-1 `;

const Title = tw.h1`text-lg md:text-xl font-medium text-center text-gray-800`;
const BtnWrapper = tw.div` w-full flex items-center justify-center space-x-10 mt-8`;

export default EditTransportationModel;
