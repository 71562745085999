import React, { useState } from "react";
import tw from "tailwind-styled-components";
import { Formik, Field, Form } from "formik";
import {
  InputGroup,
  FieldWrapper,
  Label,
  SubmitBtn,
  CombineInputGroup,
} from "../Styles/InputStyles";
import Loading from "../Loading";

import Select from "react-select";
import Model from "../Model";
import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";
const AddRawMaterialModel = ({
  AddSubAgentLoading,
  postFarmerUser,

  setOpenUploadModel,

  data,
  // companyArr,
}) => {
  const user = useSelector((state) => state.UserReducer.user);

  const [error, setError] = useState('');
  const { t, i18n } = useTranslation();
  const InitialValues = {

  };
  console.log(data)
  const SubmitHandler = (values) => {

    postFarmerUser(values);
  };
  // 
  const cropNames =
    user?.cropName?.map((crop) => ({
      value: crop.id,
      label: crop.cropName,
    })) || [];






  return (
    <Model width={`w-11/12 max-w-xl `} setOpenModel={setOpenUploadModel}>
      <Title>Add Rawmaterial </Title>

      {AddSubAgentLoading && <Loading />}

      {!AddSubAgentLoading && (
        <Formik initialValues={InitialValues} onSubmit={SubmitHandler}>
          <Form>
            <Wrapper>

              <InputGroup>
                <Label htmlFor="crop">Crop Type</Label>
                <FieldWrapper $select={true}>
                  <Field
                    required
                    name="crop"
                    id="crop"
                    autoComplete="off"
                  >
                    {(props) => (
                      <Select
                        className="w-full h-full"
                        required
                        options={cropNames}
                        classNamePrefix="select"
                        onChange={(val) => {
                          props.form.setFieldValue("crop", val.value);
                        }}
                      />
                    )}
                  </Field>
                </FieldWrapper>
              </InputGroup>
              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="gradea" >Quantity Received Grade A(bags)</Label>
                  <FieldWrapper>
                    <Field name="gradea" id="gradea" autoComplete="off" type="number" required />
                  </FieldWrapper>
                </InputGroup>
                <InputGroup>
                  <Label htmlFor="gradeb" >Quantity Received Grade B(bags)</Label>
                  <FieldWrapper>
                    <Field name="gradeb" id="gradeb" autoComplete="off" type="number" required />
                  </FieldWrapper>
                </InputGroup>
              </CombineInputGroup>
              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="source">Source</Label>
                  <FieldWrapper>
                    <Field
                      type="text"
                      name="source"
                      id="source"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>





                <InputGroup>
                  <Label htmlFor="date">Receiving Date</Label>
                  <FieldWrapper>
                    <Field
                      type="date"
                      name="date"
                      id="date"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
              </CombineInputGroup>
              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="issuegradea" >Quantity Issued Grade A(bags)</Label>
                  <FieldWrapper>
                    <Field name="issuegradea" id="issuegradea" autoComplete="off" type="number" required />
                  </FieldWrapper>
                </InputGroup>
                <InputGroup>
                  <Label htmlFor="issuegradeb" >Quantity Issued Grade B(bags)</Label>
                  <FieldWrapper>
                    <Field name="issuegradeb" id="issuegradeb" autoComplete="off" type="number" required />
                  </FieldWrapper>
                </InputGroup>
              </CombineInputGroup>

              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="balance">Balance Quantity Grade A</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="balance"
                      id="balance"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
                <InputGroup>
                  <Label htmlFor="balanceb">Balance Quantity Grade B</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="balanceb"
                      id="balanceb"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>

              </CombineInputGroup>
              <InputGroup>
                <Label htmlFor="issueDate">Issued Date</Label>
                <FieldWrapper>
                  <Field
                    type="date"
                    name="issueDate"
                    id="issueDate"
                    autoComplete="off"
                    className="truncate"
                    required
                  />
                </FieldWrapper>
              </InputGroup>
            </Wrapper>
            <BtnWrapper>
              <SubmitBtn type="submit">Add</SubmitBtn>
            </BtnWrapper>
          </Form>
        </Formik>
      )}
    </Model>
  );
};

const Wrapper = tw.div`
grid  gap-4 my-2 px-1`;


const Title = tw.h1`text-lg md:text-xl font-medium text-center text-gray-800`;
const BtnWrapper = tw.div`flex justify-center items-center mt-8`;

export default AddRawMaterialModel;
