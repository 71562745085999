import React, { useState, useEffect } from "react";
import tw from "tailwind-styled-components";
import { Link, useParams, useNavigate } from "react-router-dom";
import Images from "../../Images";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import Config from "../../Config";
import Loading from "../../Components/Loading";
import Table from "../../Components/WarehouseManager/WarehouseTable";
import Navbar from "../../Components/Navbar";
import ChangePasswordModal from "../../Components/Admin/ChangePasswordModal";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import MenuItem from "@mui/material/MenuItem";

import {
  Bg,
  BoxContainer,
  Underline,
} from "../../Components/Styles/PageStyles";
import {
  FieldWrapper,
  InputGroup,
  Label,
} from "../../Components/Styles/InputStyles";
import NotFoundModel from "../../Components/NotFoundModel";

import { useSelector } from "react-redux";

import EditWarehouseModel from "../../Components/WarehouseManager/EditWarehouseModel";
import AddWareHouseModel from "../../Components/WarehouseManager/AddWareHouseModel";
import ViewDetailModel from "../../Components/WarehouseManager/ViewDetailModel";
import { MdAdd } from "react-icons/md";

import Select from "@mui/material/Select";

const WarehouseManager = () => {
  const user = useSelector((state) => state.UserReducer.user);
  console.log(user.token);
  const [changePasswordModel, setChangePasswordModel] = useState(false);
  const [viewDetail, setViewDetail] = useState(false);
  const [openUploadModel, setOpenUploadModel] = useState(false);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [selectedData, setSelectedData] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [filter, setFilter] = useState("name");
  const [editUser, setEditUser] = useState(false);

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const initialValues = {
    name: "",
    filterVal: "",
  };

  // ----- Getting Initial Data ------
  const fetchFunction = async () => {
    const body = new FormData();
    body.append("type", Config.userType.WAREHOUSEMANAGER);
    body.append("filterType", filter);
    body.append("filterValue", filterValue);
    return await axios.post(`${Config.apiUrl}/get-managers`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const getAppReSuccess = (res) => {
    if (res.data.status == "NOK" || res.data.data == false) error = true;
  };

  const getAppReError = (res) => {};

  const {
    isLoading: isLoading,
    mutate: getAppRegMutate,
    error,
    data,
    isRefetching,
    refetch,
  } = useMutation(fetchFunction, {
    onSuccess: getAppReSuccess,
    onError: getAppReError,
  });

  useEffect(() => {
    getAppRegMutate();
  }, []);

  const submitHandler = (values) => {
    setFilterValue(values.filterVal);
    getAppRegMutate();
  };

  //------- Add Single Entry -------
  const AddWareHousePostFunction = async (values) => {
    const body = new FormData();
    body.append("name", values.fName + " " + values.lName);
    body.append("email", values.email);
    body.append("phone", values.phone);
    body.append("password", values.password);
    body.append("type", Config.userType.WAREHOUSEMANAGER);

    return await axios.post(`${Config.apiUrl}/create-user`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onAddWarehouseSuccess = (data) => {
    console.log(data);
    if (data?.data?.status == "OK") {
      toast.success(data?.data?.msg || "Success");
      // refetch();
      getAppRegMutate();
    } else {
      toast.error(data?.data?.msg);
    }
    setSelectedData("");
    setOpenUploadModel(false);
  };

  const onAddWarehouseError = (data) => {
    setOpenUploadModel(false);
    setSelectedData("");
    toast.error(data?.response?.data?.msg);
  };

  const { isLoading: SingleEntryLoading, mutate: postSingleEntry } =
    useMutation(AddWareHousePostFunction, {
      onSuccess: onAddWarehouseSuccess,
      onError: onAddWarehouseError,
    });

  //------- Edit Single Entry -------
  const EditWarehouseFunction = async (values) => {
    const body = new FormData();
    body.append("name", values.name);
    body.append("email", values.email);
    body.append("phone", values.phone);
    body.append("status", values.status);
    body.append("id", values.id);

    return await axios.post(`${Config.apiUrl}/update-user`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onEditWarehouseSuccess = (data) => {
    if (data?.data?.status == "OK") {
      toast.success(data?.data?.msg || "Success");
      getAppRegMutate();
    } else {
      toast.error(data?.data?.msg);
    }
    // getAppRegMutate();

    setSelectedData("");
    setEditUser(false);
  };

  const onEditWarehouseError = (data) => {
    setEditUser(false);
    setSelectedData("");
    toast.error(data?.response?.data?.msg);
  };

  const { isLoading: editEntryLoading, mutate: editAddUser } = useMutation(
    EditWarehouseFunction,
    {
      onSuccess: onEditWarehouseSuccess,
      onError: onEditWarehouseError,
    }
  );

  // change password
  const changePasswordFunction = async (values) => {
    const body = new FormData();
    body.append("password", values.password);
    body.append("email", selectedData.email);
    //console.log( selectedData.email);
    body.append("id", selectedData.id);
    body.append("confirmPassword", values.confirmPassword);
    return await axios.post(Config.apiUrl + "/change-password", body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onChangePasswordSuccess = (data) => {
    console.log("success", data?.data);
    toast.success(data?.data?.msg, "Success");
    setChangePasswordModel(false);
  };

  const onChangePasswordError = (data) => {
    console.log("error", data?.data);
    toast.error(data?.response?.data?.msg, "An Error Occured");
  };

  const { mutate: changePasswordMutate, isLoading: changePasswordLoading } =
    useMutation(changePasswordFunction, {
      onSuccess: onChangePasswordSuccess,
      onError: onChangePasswordError,
    });

  return (
    <>
      <Bg
        style={{
          backgroundImage: `url(${Images.BeamsBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "top left",
          height: "100vh",
        }}
      >
        <Navbar />
        {changePasswordModel && (
          <ChangePasswordModal
            setChangePasswordModel={setChangePasswordModel}
            changePasswordLoading={changePasswordLoading}
            changePasswordMutate={changePasswordMutate}
          />
        )}
        {viewDetail && (
          <ViewDetailModel
            setViewDetail={setViewDetail}
            selectedData={selectedData}
          />
        )}
        {/* <Page> */}
        <BoxContainer>
          <div className="flex w-full justify-between items-center">
            <div>
              <Title>{t("Warehouse Manager")}</Title>
              <Underline />
            </div>

            {user?.userType == Config.userType.ADMIN && (
              <Uploader setOpenUploadModel={setOpenUploadModel} t={t} />
            )}
          </div>

          <div className="flex w-full justify-between items-center">
            <Filters
              submitHandler={submitHandler}
              initialValues={initialValues}
              InitialLoading={isLoading}
              setFilter={setFilter}
              filter={filter}
              user={user}
              t={t}
            />
          </div>
          {openUploadModel && (
            <AddWareHouseModel
              postSingleEntry={postSingleEntry}
              SingleEntryLoading={SingleEntryLoading}
              setOpenUploadModel={setOpenUploadModel}
            />
          )}

          {editUser && (
            <EditWarehouseModel
              editAddUser={editAddUser}
              editEntryLoading={editEntryLoading}
              setEditUser={setEditUser}
              data={data?.data}
              selectedData={selectedData}
            />
          )}

          {!error && !isLoading && data && (
            <TableWrapper>
              <Table
                ApiData={error ? [] : data?.data?.data?.userList}
                setOpenDeleteModel={setOpenDeleteModel}
                setSelectedData={setSelectedData}
                setEditUser={setEditUser}
                setChangePasswordModel={setChangePasswordModel}
                setViewDetail={setViewDetail}
              />
            </TableWrapper>
          )}
          {(isLoading || isRefetching) && <Loading />}
          {(error || data?.data?.data?.userList?.length === 0) &&
            !isLoading && <NotFoundModel />}
        </BoxContainer>
      </Bg>
    </>
  );
};

const Filters = ({
  initialValues,
  submitHandler,
  InitialLoading,
  user,
  filter,
  setFilter,
  t,
}) => {
  return (
    <Formik initialValues={initialValues} onSubmit={submitHandler}>
      <Form>
        <FilterContainer>
          <InputGroup className="max-w-xs" style={{ flexBasis: "content" }}>
            <Label htmlFor="filter">{t("Search")}</Label>

            <Select
              autoComplete="off"
              className="w-36 pl-2 bg-transparent text-sm p-0 border-none"
              style={{ height: "40px" }}
              value={filter}
              onChange={(e) => {
                setFilter(e.target.value);
              }}
            >
              <MenuItem value="name">By Name</MenuItem>
              {/* <MenuItem value="email">By Email</MenuItem> */}
              <MenuItem value="phone">By Phone</MenuItem>
            </Select>
          </InputGroup>
          <InputGroup className="max-w-xs">
            <Label htmlFor="filterVal">{t("Value")}</Label>
            <FieldWrapper className="h-10">
              <Field type="text" name="filterVal" id="filterVal" class="ml-1" />
            </FieldWrapper>
          </InputGroup>

          <ApplyBtn disabled={InitialLoading} type="submit">
            {t("Search")}
          </ApplyBtn>
        </FilterContainer>
      </Form>
    </Formik>
  );
};

const Uploader = ({ setOpenUploadModel, t }) => {
  return (
    <UploadWrapper>
      <Button type="button" onClick={() => setOpenUploadModel(true)}>
        <MdAdd className="text-white w-5 h-5" /> <p>{t("Warehouse Manager")}</p>
      </Button>
    </UploadWrapper>
  );
};

const TableWrapper = tw.h1`my-10 border bg-white  rounded-md overflow-auto`;

const Title = tw.h1`text-xl  text-gray-800 whitespace-nowrap   font-medium`;

const UploadWrapper = tw.div`
 w-full flex justify-end space-x-4 items-center `;

const Button = tw.button`text-white bg-custom-green hover:bg-green-700 w-40 flex items-center space-x-1 justify-center h-10   text-xs whitespace-nowrap rounded`;

const ApplyBtn = tw.button`${(p) =>
  p.$active
    ? "bg-gray-100"
    : ""} border whitespace-nowrap border-gray-200 flex space-x-1.5 justify-center items-center text-white h-full rounded-md px-6  cursor-pointer bg-custom-green hover:bg-green-700  py-2.5 text-sm`;

const FilterContainer = tw.div`
grid grid-cols-2 w-full gap-4 md:flex md:space-x-4 items-end mt-12 md:mt-8`;

export default WarehouseManager;
